import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fa154eb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px",
  id: "kt-wh-menu",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "menu-item px-2" }
const _hoisted_3 = {
  key: 0,
  class: "separator my-2 mx-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItem, (item, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_router_link, {
            to: item.path,
            class: "menu-link px-5"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(item.title), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        (index !== _ctx.menuItem.length - 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}