
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import { Decode, Encode } from "@/services";
import userApi from "@/api/user/";
import apiConfig from "@/config/api";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const i18n = useI18n();
    const store = useStore();

    i18n.locale.value = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";

    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: "English",
      },
      es: {
        flag: "media/flags/spain.svg",
        name: "Spanish",
      },
      de: {
        flag: "media/flags/germany.svg",
        name: "German",
      },
      ja: {
        flag: "media/flags/japan.svg",
        name: "Japanese",
      },
      fr: {
        flag: "media/flags/france.svg",
        name: "French",
      },
    };

    const signOut = async () => {
      // store
      //   .dispatch(Actions.LOGOUT)
      //   .then(() => router.push({ name: "authentication-base-sign-in" }));
      await localStorage.clear();
      router.push("/public");
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    return {
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
    };
  },
  data: () => ({
    user: "",
    userName: "",
    userId: "",
    classRole: "badge badge-light-success fw-bolder fs-8 px-2 py-1",
    cssSignOut: "color: #F1416C;",

    imgShow: "",
  }),
  created() {
    this.getUser();
  },
  methods: {
    async getUser() {
      this.userId = Decode.decode(localStorage.getItem("boss"));
      let responseData = [];

      try {
        responseData = await userApi.user.getOne(this.userId);

        this.imgShow = responseData["data"].file
          ? `${apiConfig.ENDPOINT}${responseData["data"].file.path}`
          : "media/avatars/blank.png";
      } catch (error) {
        console.log(error);
      }

      if (responseData["response_status"] === "SUCCESS") {
        this.user = responseData["data"];
        this.userName =
          responseData["data"]["firstName"] +
          " " +
          responseData["data"]["lastName"];
        this.setClassRole();
      }
    },
    setClassRole() {
      this.user["role"] === "SYSTEM ADMIN"
        ? (this.classRole =
            "badge badge-light-primary fw-bolder fs-8 px-2 py-1")
        : this.user["role"] === "BUSINESS OWNER"
        ? (this.classRole =
            "badge badge-light-success fw-bolder fs-8 px-2 py-1")
        : this.user["role"] === "BUSINESS DIRECTOR"
        ? (this.classRole =
            "badge badge-light-warning fw-bolder fs-8 px-2 py-1")
        : this.user["role"] === "MANAGER"
        ? (this.classRole = "badge badge-light-danger fw-bolder fs-8 px-2 py-1")
        : this.user["role"] === "STAFF"
        ? (this.classRole = "badge badge-light-info fw-bolder fs-8 px-2 py-1")
        : (this.classRole =
            "badge badge-light-success fw-bolder fs-8 px-2 py-1");
    },

    enterSignOut() {
      this.cssSignOut = "color: #D9214E; background-color: #FFF5F8";
    },

    leaveSignOut() {
      this.cssSignOut = "color: #F1416C;";
    },
  },
});
