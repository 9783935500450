import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = { class: "d-flex align-items-stretch ms-1 ms-lg-3" }
const _hoisted_3 = { class: "d-flex align-items-center ms-1 ms-lg-3" }
const _hoisted_4 = {
  class: "btn btn-icon btn-active-light-primary",
  "data-kt-menu-target": "#kt-quick-links-menu",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_5 = { class: "svg-icon svg-icon-1" }
const _hoisted_6 = {
  class: "d-flex align-items-center ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_7 = {
  class: "cursor-pointer symbol symbol-circle symbol-30px symbol-md-40px",
  "data-kt-menu-target": "#kt-user-menu",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTSearch = _resolveComponent("KTSearch")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_KTMyApps = _resolveComponent("KTMyApps")!
  const _component_MenuComponent = _resolveComponent("MenuComponent")!
  const _component_KTUserMenu = _resolveComponent("KTUserMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_KTSearch)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_MenuComponent, { "menu-selector": "#kt-quick-links-menu" }, {
        toggle: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen025.svg" })
            ])
          ])
        ]),
        content: _withCtx(() => [
          _createVNode(_component_KTMyApps)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_MenuComponent, { "menu-selector": "#kt-user-menu" }, {
        toggle: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("img", {
              alt: "Pic",
              src: _ctx.imgShow ? _ctx.imgShow : ' media/avatars/blank.png'
            }, null, 8, _hoisted_8)
          ])
        ]),
        content: _withCtx(() => [
          _createVNode(_component_KTUserMenu)
        ]),
        _: 1
      })
    ])
  ]))
}