
import { defineComponent } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";

import KTUserMenu from "@/layout/header/partials/WarehouseMenu.vue";
import MenuComponent from "@/components/menu/MenuComponent.vue";

export default defineComponent({
  name: "kt-my-apps",
  components: {
    Dropdown1,
    MenuComponent,
    KTUserMenu,
  },
  setup() {
    const apps = [
      // {
      //   icon: "media/svg/brand-logos/amazon-icon-1.svg",
      //   title: "AWS",
      // },
      // {
      //   icon: "media/svg/brand-logos/angular-icon-1.svg",
      //   title: "AngularJS",
      // },
      // {
      //   icon: "media/svg/brand-logos/atica.svg",
      //   title: "Atica",
      // },
      // {
      //   icon: "media/svg/brand-logos/beats-electronics.svg",
      //   title: "Music",
      // },
      // {
      //   icon: "media/svg/brand-logos/codeigniter.svg",
      //   title: "Codeigniter",
      // },
      // {
      //   icon: "media/svg/brand-logos/bootstrap-4.svg",
      //   title: "Bootstrap",
      // },
      // {
      //   icon: "media/svg/brand-logos/google-tag-manager.svg",
      //   title: "GTM",
      // },
      // {
      //   icon: "media/svg/brand-logos/disqus.svg",
      //   title: "Disqus",
      // },
      // {
      //   icon: "media/svg/brand-logos/dribbble-icon-1.svg",
      //   title: "Dribble",
      // },
      // {
      //   icon: "media/svg/brand-logos/google-play-store.svg",
      //   title: "Play Store",
      // },
      // {
      //   icon: "media/svg/brand-logos/google-podcasts.svg",
      //   title: "Podcasts",
      // },
      // {
      //   icon: "media/svg/brand-logos/figma-1.svg",
      //   title: "Figma",
      // },
      // {
      //   icon: "media/svg/brand-logos/github.svg",
      //   title: "Github",
      // },
      // {
      //   icon: "media/svg/brand-logos/gitlab.svg",
      //   title: "Gitlab",
      // },
      // {
      //   icon: "media/svg/brand-logos/pinterest-p.svg",
      //   title: "Pinterest",
      // },
      {
        icon: "https://www.svgrepo.com/show/288668/monitor-screen.svg",
        title: "POS",
      },
      {
        icon: "https://www.svgrepo.com/show/236749/box.svg",
        title: "Stock",
      },
      {
        icon: "https://www.svgrepo.com/show/236734/list.svg",
        title: "Report",
      },
    ];

    return {
      apps,
    };
  },
  methods: {
    goByMyApp(index) {
      if (index === 0) {
        // window.open("https://www.instagram.com/", "_blank");
        this.$router.push("/sale/SA5");
      }
      if (index === 1) {
        // window.open("https://www.facebook.com/", "_blank");
        this.$router.push("/warehouse/WH1");
      }
      if (index === 2) {
        // window.open("https://www.line.me/", "_blank");
        this.$router.push("/report/summary-sales-customer-report");
      }
    },
  },
});
