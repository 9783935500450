
import { defineComponent } from "vue";
import KTSearch from "@/layout/header/partials/Search.vue";
import MenuComponent from "@/components/menu/MenuComponent.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import KTMyApps from "@/layout/header/partials/MyApps.vue";
import KTNotificationMenu from "@/layout/header/partials/NotificationMenu.vue";

import { Decode, Encode } from "@/services";
import apiConfig from "@/config/api";
import userApi from "@/api/user/";

export default defineComponent({
  name: "topbar",
  components: {
    KTUserMenu,
    KTMyApps,
    KTNotificationMenu,
    KTSearch,
    MenuComponent,
  },

  data: () => ({
    user: "",
    userName: "",
    userId: "",
    classRole: "badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2",
    cssSignOut: "color: #F1416C;",

    imgShow: "",
  }),

  created() {
    this.getUser();
  },

  methods: {
    async getUser() {
      this.userId = Decode.decode(localStorage.getItem("boss"));
      let responseData = [];

      try {
        responseData = await userApi.user.getOne(this.userId);

        this.imgShow = responseData["data"].file
          ? `${apiConfig.ENDPOINT}${responseData["data"].file.path}`
          : "media/avatars/blank.png";
      } catch (error) {
        console.log(error);
      }

      if (responseData["response_status"] === "SUCCESS") {
        this.user = responseData["data"];
        this.userName = responseData["data"]["firstName"];
      }
    },
  },
});
