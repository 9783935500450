
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components";
import MenuComponent from "@/components/menu/MenuComponent.vue";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";

import SystemRole from "@/core/config/MainMenuConfig1";
import OwnerRole from "@/core/config/MainMenuConfig";
import DirectorRole from "@/core/config/MainMenuConfig2";

import { Decode, Encode } from "@/services";

export default defineComponent({
  name: "kt-menu",
  components: {
    MenuComponent,
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      OwnerRole,
      SystemRole,
      DirectorRole,
      asideMenuIcons,
      version,
      translate,
    };
  },
  data: () => ({
    hoverByClickDb: "",
    hoverByClickOther: "",
    index1: "",
    index2: "",
    textColorDb: "",
    textColorOther: "",

    role: "",
  }),
  created() {
    const k: number = Number(localStorage.getItem("indexK"));
    const j: number = Number(localStorage.getItem("indexJ"));
    this.hoverListOther(k, j);

    this.role = Decode.decode(localStorage.getItem("role")).replace(
      /^"(.*)"$/,
      "$1"
    );
  },
  methods: {
    subItemChange() {
      this.hoverByClickOther = "";
      this.textColorOther = "";
    },
    hoverListDb() {
      this.hoverByClickDb = "background-color: AliceBlue;";
      this.textColorDb = "color: #3699ff";
    },
    hoverListOther(k, j) {
      localStorage.setItem("indexK", k);
      localStorage.setItem("indexJ", j);
      this.index1 = j;
      this.index2 = k;
      this.hoverByClickDb = "";
      this.hoverByClickOther = "background-color: AliceBlue;";

      this.textColorDb = "";
      this.textColorOther = "color: #3699ff";
    },
  },
});
